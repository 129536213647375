.item_container {
    background-color: rgba(253, 249, 249, 0.493);
    height: 150px;
    position: relative;
    display: flex;
    margin-top: 20px;
}
.eatery_area {
    height: 26px;
    width: 18%;
    margin-left: 12px;
    position: absolute;
    display: flex;
}

.item_eatery_name{
    height: 24%;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    position: relative;
}

.item_image_area {
    height: 100px;
    width: 17%;
    margin-top: 28px;
    margin-left: 12px;
    margin-bottom: 10px;
    position: relative;


}

.info_area {
    /* margin-top: 26px; */
    margin-bottom: 10px;
    height: 100%;
    width: 34%;
    position: relative;
    display: flex;

}
.price_area {
    margin-top: 25px;
    margin-bottom: 10px;
    margin-right: 5px;
    height: 80px;
    width: 25.5%;
    position: relative;
    display: flex;
    /* border: 1px black solid; */
}
.item_image {
    height: 100%;
    width: 92%;
    margin-left: 0;
}


.item_title {
    height: 30%;
    width: 100%;
    margin-top: 25px;
    font-size: x-large;
    font-weight: bold;
    position: relative;
}

.item_desc {
    height: 40%;
    width: 100%;
    margin-left: 2px;
    margin-top: 28px;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
}
.item_rating {
    margin-top: 75px;
    margin-left: 2px;
    position: absolute;
    font-size:xx-small;
}

.item_date {
    margin-top: 95px;
    margin-left: 2px;
    height: 21%;
    width: 100%;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
}

.item_available_time {
    margin-top: 115px;
    margin-left: 2px;
    height: 21%;
    width: 100%;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
}



.price_zoom {
    height: 80px;
    width: 31%;
    position: relative;

    display: flex;
}

.item_price{
    text-decoration: line-through;
    margin-top: 5px;
    position: absolute;
    font-size: medium;
    font-weight: bold;
    color: gray;
}

.item_newprice{
    margin-top: 24px;
    position: absolute;
    font-size: large;
    font-weight: bold;
}

.act_zoom {
    height: 80px;
    width: 133.38px;
    position: relative;

    display: flex;
}
.item_rest {
    margin-top: 5px;
    margin-left: 85px;
    position: absolute;
    width: 100%;
    height: 100%;
    font-size: large;
    font-weight: bold;
    color: red;
}

.item_remove {
    margin-top: 100px;
    margin-left: 90px;
    height: 30%;
    width: 50%;
    position: absolute;
    font-size: medium;
}

.item_act_area {
    height: 100%;
    width: 20%;
    position: relative;
    display: flex;
    /* margin-top: 25px; */
}

.cartpage_act_area {
    height: 55px;
    width: 651px;
}

button#check_outbtn {
    margin-left: 680px;
    margin-top: 30px;
}

.cartpage_checkout {
    height:55px;
    width: 130px;

    /* text-align: center; */
    /* font-size: xx-large;
    position: absolute;
    background-color: #1890FF;
    color: aliceblue;
    display: inline-block; */
}
/* 
.item_add {
    margin-top: 10px;
    margin-left: 87px;
    width: 20%;
    position: absolute;
    font-size: 15px;
    font-weight: bold;
}

.input_num {
    margin-top: 10px;
    margin-left: 23.3px;
    width: 49%;
    position: absolute;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.item_minus {
    margin-top: 10px;
    width: 20%;
    position: absolute;
    font-size: 15px;
    font-weight: bold;
} */

/* .item_add_wish{
    margin-top: 40px;
    margin-left: 35.5px;
    height: 25%;
    width: 70%;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    background-color: rgb(151, 202, 164);
} */

/* .item_totalprice {
    height: 20px;
    width: 60px;
    position: absolute;
    margin-top: 10px;
    font-size:small;
    font-weight:bold;
} */

/* .page_total_price {
    height:21px;
    width: 102px;
    position: absolute;
    margin-left: 470px;

} */
