.deal_title {
  padding-bottom: 20px;
}

.deal_title h1,
.deal_info > h1 {
  font-size: xx-large;
  font-weight: bold;
  display: inline-block;
}

.deal_title h2,
.deal_info > p {
  font-size: large;
  margin-left: 10px;
}

.deal_body {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  position: relative;
}

.deal_image_list {
  width: 66.7%;
  list-style: none;
  overflow: hidden;
  position: relative;
}

.deal_image_list img {
  height: 500px;
  padding-right: 20px;
}

.deal_image_list:hover .right_btn, .deal_image_list:hover .left_btn {
  z-index: 9999;
  display: block;
}

.deal_image_list > button:hover {
  cursor: pointer;
  box-shadow: 15px -15px 0 0 rgb(0, 0, 0, 0.8)  inset;
}

.left_btn, .right_btn {
  border: none;
  display: none;
  position: absolute;
  top: 45%;
  width: 50px;
  height: 50px;
  line-height: 30px;
  cursor: pointer;
  background-color:transparent;
  box-shadow: 15px -15px 0 0 rgb(0, 0, 0, 0.4)  inset;
}

.left_btn {
  left: 15px;
  transform: rotate(45deg);
}

.right_btn {
  right: 15px;
  transform: rotate(225deg);
}

.deal_vouchers {
  width: 33.3%;
  padding: 0 30px;
}

.deal_vouchers > button, .eatery_vouchers > button {
  width: 100%;
  height: 40px;
  font-size: medium;
  font-weight: 600;
  color: #fff;
  background-color: #fff;
  border: 1px solid #2999f5;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  cursor: pointer;
}

.deal_vouchers li {
  margin-top: 10px;
  border-bottom: 1px solid #e6e7e8;
  padding-bottom: 10px;
}

.deal_vouchers li input[type='radio']:checked {
  width: 15px;
  height: 15px;
  border: 2px solid #a5a8ab;
  border-radius: 50%;
  background-color: #0070cc;
  border-color: #0070cc;
  margin: .4rem;
  position: absolute;
}

.deal_vouchers > ul > li > label > div {
  display: block;
  padding-left: 30px;
}

.deal_vouchers .deal_status {
  margin: 20px 0;
  font-size: medium;
}

.deal_info {
  display: block;
  position: relative;
}