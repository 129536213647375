.page_container {
  padding: 0 64px;
}

.page_container {
  margin: 0 auto;
  width: 100%;
}

.header_wrapper {
  border-bottom: 1px solid rgb(233, 229, 229);
  width: 100%;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
  position: absolute;
}

.header_wrapper .wrapper {
  max-width: 1528px;
  padding-bottom: 0;
  padding-top: 8px;
}

.wrapper {
  display: flex;
  position: relative;
  padding-bottom: 0;
  padding-top: 8px;
}

.wrapper > nav{
  font-size: small;
  margin-left: auto;
  padding: 8px;
}

.wrapper a{
  margin: 0 0 0 24px;
}

.header_wrapper .header_top {
  max-width: 1528px;
  padding-bottom: 24px;
  padding-top: 8px;
}

.header_top {
  display: flex;
  position: relative;
  height: 100px;
}

.header_top .logo {
  position: absolute;
  bottom: 115%;
  margin-left: 90px;
}

.header_top .parent {
  position: relative;
  margin-left: 350px;
}

.header_top .search {
  width: 78%;
  height: 40px;
  border-radius: 18px;
  outline: none;
  border: 1px solid #ccc;
  padding-left: 20px;
}

.header_top .search_btn {
  height: 35px;
  width: 35px;
  background:  url('../search.png')  no-repeat;
  margin-left: 555px;
  bottom: 55%;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
}

.header_top .user_menu {
  position: absolute;
  right: 180px;
  top: 10%;
}

.header_top a,
.header_top button {
  float: left;
  display: block;
  height: 35px;
  width: 35px;
  margin-right: 15px;
}

.header_top .user_profile {
  float: left;
}

.header_top .user_profile button{
  position: absolute;
  font-size: x-large;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #d5d8db;
  width: 90px;
  height: 35px;
}

.header_top .profile {
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: none;
}

.header_top .dropdown_menu {
  position: absolute;
  display: none;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  top: 100%;
  z-index: 9999;
}

.header_top .dropdown_menu a{
  float: none;
  font-size: large;
  height: 25px;
  width: 110px;
  margin: 8px 5px;
}

.header_top .dropdown_menu a:hover {
  text-decoration: underline;
}
