.card:hover img {
  max-width: 32em;
  transform:scale(0.99);
  box-shadow: inset 0 0 1em rgba( 0, 0, 0, 0.3 ),
          0.5em 0.5em 0.5em rgba( 0, 0, 0, 0.3 );
}

:root {
  --star-size: 20px;
  --star-color: rgb(151, 146, 146);
  --star-background: #fc0;
}

.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
}

.Stars::before {
  content: '★★★★★';
  letter-spacing: 3px;
  background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.pagination {
  display: block;
  width: 300px;
  height: 40px;
  margin: 0 auto;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 12px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}
